import { useState, useEffect, Fragment } from "react";
import { FiArrowLeft, FiArrowRight } from "react-icons/fi";
import { ReactTyped } from "react-typed";
import api from '../api/api'
import { redirect } from "react-router-dom";
// import axios from 'axios';

const chloeSpeech = [
    "Hi there! I&apos;me Chloe, your AI assitent." +
    "<br />" +
    "I thrive on collaboration and genuinely enjoy having humans around me. It&apos;s through our interactions that I get to learn and grow, constantly improving to serve you better. My goal is simple: to assist you in any way I can, making your tasks easier and your operations more seamless. Whether you&apos;re dealing with data privacy concerns, looking to streamline your IT operations, or need a hand in customizing AI solutions, I&apos;m here to help." +
    "<br />" +
    "<br />" +
    "Together, we can unlock the full potential of your business operations, turning challenges into opportunities. So, let’s make great things happen. I’m excited to be on this journey with you and look forward to contributing to our collective success!"
];

const ChloeSignup = () => {


    const [start, setStart] = useState(false);

    const [chatInput, setChatInput] = useState("");

    const [lastChat, setLastChat] = useState("name");
    const [formResponses, setFormResponses] = useState({})

    const [requestEmail, setRequestEmail] = useState(false);
    const [requestUsecase, setRequestUsecase] = useState(false);
    const [requestPolicy, setRequestPolicy] = useState(false);

    const [success, setSuccess] = useState(false);


    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            api.SendChloeSignupChat({
                name: formResponses['name'],
                email: formResponses['email'],
                usecase: formResponses['usecase'],
            })
                .finally(() => {
                    console.log("DONE")
                    setSuccess(true)
                })
        } catch (err) {
            console.log(err);
            return redirect("/")
        }

    }

    const nextChat = () => {
        setFormResponses({
            ...formResponses,
            [lastChat]: chatInput
        })

        switch (lastChat) {
            case 'name':
                setRequestEmail(true);
                setLastChat('email')
                break;
            case 'email':
                setRequestUsecase(true);
                setLastChat('usecase')
                break;
            case 'usecase':
                setRequestPolicy(true);
                setLastChat('policy')
                break;
            default:
                alert(JSON.stringify(formResponses))
        }

    }

    const handleChatSubmit = () => {
        if (chatInput) {
            nextChat()
        }
        setChatInput("");
    }

    const handleChatKeyUp = (e) => {
        e.preventDefault()

        if (e.key == "Enter") {
            handleChatSubmit()
            return
        }
    }


    return <>
        <div className="rwt-about-area rn-section-gap">
            <div className="container">
                <div className="rn-timeline-wrapper timeline-style-one position-relative time-line-full-page">
                    <div id="step-1" className="single-timeline mt--50" key="step1">
                        <div className="single-content">
                            <div className="inner" style={{ width: "100%" }}  >
                                <div className="align-items-stretch row">
                                    <div className="col-lg-5">
                                        <div className="thumbnail">
                                            <img className="w-100 shadow" src="/assets/img/chloe2.png" alt="Chloe" style={{ border: "2px solid hsl(0,10%,20%)", padding: "20px", paddingBottom: "0px", background: "repeating-linear-gradient(hsl(0,80%, 20%) 1px, black 6px,white 5px)" }} />
                                        </div>
                                    </div>

                                    <div className="col-lg-7 d-flex align-items-strech">
                                        <div style={{ border: "0px solid red", background: "hsla(0,0%,0%, 0)", flexGrow: "1", display: "flex", flexDirection: "column", maxHeight: "360px", overflow: "hidden", justifyContent: start ? "end" : "start" }}>
                                            {success
                                            ? <>
                                                <h2 className="display-5">Thank you for signing up!</h2>
                                            </>
                                            :!start
                                                ? <>
                                                    <h2 className="display-4" >Hi there!<br /> {" "}</h2>
                                                    <ReactTyped strings={chloeSpeech} typeSpeed={5} contentType="html" startDelay={1000} />
                                                </>
                                                : <>
                                                    <div style={{ flexGrow: "1", display: "flex", flexDirection: "column", alignItems: "start", justifyContent: "end", maxHeight: "360px", padding: "10px" }}>
                                                        <ReactTyped showCursor={false} typeSpeed={20} contentType="html" startDelay={1000} style={{ fontWeight: "bold" }} strings={[
                                                            "Great! What if your name?<br />"
                                                        ]} />
                                                        {requestEmail && <>
                                                            {formResponses['name']}
                                                            <ReactTyped showCursor={false} typeSpeed={20} contentType="html" startDelay={1000} style={{ fontWeight: "bold" }} strings={[
                                                                "<br />Thanks! And what is your email address?<br />"
                                                            ]} />
                                                        </>}
                                                        {requestUsecase && <>
                                                            {formResponses['email']}
                                                            <ReactTyped showCursor={false} typeSpeed={20} contentType="html" startDelay={1000} style={{ fontWeight: "bold" }} strings={[
                                                                "<br />Perfect! We&apos;getting somewhere. Now, please explain your use case"
                                                            ]} />
                                                        </>}
                                                        {requestPolicy && <>
                                                            {formResponses['usecase']}
                                                            <ReactTyped showCursor={false} typeSpeed={20} contentType="html" startDelay={1000} style={{ fontWeight: "bold" }} strings={[
                                                                "<br />That&apos; exciting!<br />We&apos;re done for now, It was indeed nice to meet you! We will contact you soon.<br />Please read our privacy policy before submiting this chat.<br />Have a beautiful day!"
                                                            ]} />

                                                        </>}
                                                    </div>

                                                    {lastChat == 'policy' ? <span>&nbsp;</span>
                                                        : <div style={{ display: "flex", flexDirection: "row" }}>
                                                            <input
                                                                autoFocus={true}
                                                                style={{ flexGrow: "1" }}
                                                                type="email"
                                                                name="email"
                                                                placeholder=""
                                                                required
                                                                value={chatInput}
                                                                onChange={e => setChatInput(e.target.value)}
                                                                onKeyUp={handleChatKeyUp}
                                                            />
                                                            <button className="btn btn-secondary ms-3" style={{ border: '0px' }} onClick={handleChatSubmit}>
                                                                <FiArrowRight style={{ fontSize: "30px" }} />
                                                            </button>
                                                        </div>}
                                                </>}
                                        </div>
                                    </div>
                                </div>

                                <div className="read-more-btn text-center" style={{ marginTop: "100px" }}>
                                    {success
                                    ? <span>&nbsp;</span>
                                    : !start 
                                        ? <a className="btn-default" href="#" onClick={e => { e.preventDefault; setStart(true) }}><span>Start Now</span></a>
                                        : lastChat == 'policy'
                                            ? <a className="btn-default" href="#" onClick={handleSubmit}><span>Submit chat</span></a>
                                            : <span>&nbsp;</span>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>

}

export default ChloeSignup