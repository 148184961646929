import PropTypes from "prop-types";
import React from 'react';
import { Link } from "react-router-dom";

const Logo = ({ image, image2 }) => {
    return (
        <div className="logo">
            <Link to={"/"} className="d-flex flex-row jusify-content-center align-items-center">
                <img className="logo-light" src={image} alt="Corporate Logo" />
                <img className="logo-dark" src={image2} alt="Corporate Logo" />

                <span className="logo-name mx-3 d-none d-sm-block" style={{ fontSize: '20px', fontWeight: '600', color: 'hsl(0,0%,90%' }}>
                    Infinity<span className="color-primary">Ops</span>&nbsp;Consulting
                </span>
            </Link>
        </div>
    )
}
Logo.propTypes = {
    image: PropTypes.string
};

export default Logo;
