import { useState } from "react";
import api from "../../api/api";
import Logo from "../../elements/logo/Logo";
import ScrollTop from "./ScrollTop";

const FooterOne = () => {
    const [email, setEmail] = useState("")
    const [done, setDone ] = useState(false);

    const handleSubscription = () => {
        if(email) {
            api.SubscribeToNewsletter(email);
            setEmail("")
            setDone(true)
        }
    }

    return (
        <>
            <footer className="rn-footer footer-style-default p-5" style={{background: "hsl(0,0%,10%)"}}>
                <div className="container">
                    <div className="row" >
                        <div className="col-12 col-md-6 text-center">
                            <Logo
                                image={`/logo.png`}
                                image2={`/logo.png`}
                            />
                        </div>
                        <div className="col-12 col-md-6 d-flex justify-content-center align-items-center flex-column">
                            <h4 className="title">Stay with us</h4>
                            <div className="inner">
                                <h6 className="subtitle">Subscribe to our newsletter and stay informed about our latest news and updates.</h6>
                                <form className="newsletter-form" action="#">
                                    {done 
                                    ? <><span>Thank you</span></>
                                    :<div className="form-group d-flex col-12">
                                        <input type="email" placeholder="Enter Your Email Here" className="col-12 col-md-6"  value={email} onChange={e => setEmail(e.target.value)} required />
                                        <button className="btn-default col-12 col-md-6" type="submit" onClick={handleSubscription}>Submit Now</button>
                                    </div>
                                    }  
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
            <ScrollTop />
        </>
    )
}

export default FooterOne

