import PropTypes from "prop-types";
import React from 'react'
import useSEO from "../hooks/useSEO";

const SEO = ( ) => {
    const { title, subtitle, description } = useSEO()
    return (
        <>
            <meta charSet="utf-8" />
            <title>{title} | {subtitle}</title>
            <meta name="robots" content="noindex, follow" />
            <meta name="description" content={description} />
            <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
            <link rel="shortcut icon" href="/favicon.ico" />
        </>
    )
}

export default SEO;


