import { useState } from "react";

const useSEO = () => {
    const [ title, setTitle ] = useState("InfinityOps Consulting");
    const [ subtitle, setSubtitle ] = useState("Transforming Operations with AI");
    const [ description, setDescription ] = useState("Transforming Operations with AI");
    const [ image, setImage ] = useState("/assets/img/logo.png");

    return {
        title,
        setTitle,
        subtitle,
        setSubtitle,
        description,
        setDescription,
        image,
        setImage,
    };
};

export default useSEO;