const ServiceChloePlaybookCustomization = () => {
    return <>

        <div className="rn-blog-area rn-section-gap">
            <div className="container">
                <div className="row row--30">
                    <div className="col-lg-12"></div>
                    <h3>Introducing Chloe: Your Secure and Customizable AI Agent Framework</h3>
                    <p><strong>Unleash the power of AI while respecting European privacy regulations and the highest security standards.</strong></p>
                    <p>Chloe is InfinityOps' revolutionary AI agent framework, designed specifically for businesses operating in Europe. Built with Go for exceptional performance and security, Chloe empowers you to leverage AI within your organization while adhering to strict data privacy regulations like GDPR and ISM standards.</p>
                    <p><strong>Here's what sets Chloe apart:</strong></p>
                    <ul>
                        <li><strong>European Privacy by Design:</strong> Chloe prioritizes data security and privacy. You can choose to run on-premises Large Language Models (LLMs) to ensure your data never leaves your infrastructure, ideal for time-critical environments and sensitive information.</li>
                        <li><strong>Customizable with Playbooks:</strong> Develop tailored AI workflows with Chloe's unique "playbook" concept. Playbooks orchestrate data processing flows, allowing you to create complex AI solutions that seamlessly integrate with your existing systems.</li>
                        <li><strong>Limitless Integrations:</strong> Leverage Chloe's open API and SDKs to build custom plugins and connect to an extensive range of AI providers and tools. Ollama and LangChain empower Chloe to seamlessly integrate with any LLM, future-proofing your AI strategy.</li>
                        <li><strong>Dockerized Security:</strong> We leverage Docker containers for deployment, ensuring complete data and network segregation, further safeguarding your sensitive information. (Please note, Chloe itself is not intended for client implementation in their Docker infrastructures. This is an internal implementation detail for our benefit).</li>
                    </ul>
                    <h3>Empowering Businesses with AI: Use Cases for Chloe</h3>
                    <ul>
                        <li><strong>AI-Enhanced APIs:</strong> Expose HTTP services and integrate your existing tools with custom AI workflows. Chloe empowers you to automate tasks, streamline processes, and extract valuable insights from your data.</li>
                        <li><strong>Automated Incident Response:</strong> Build playbooks for real-time threat detection and response. Chloe can analyze security logs, identify anomalies, and trigger automated actions to mitigate threats and minimize downtime.</li>
                        <li><strong>Intelligent Document Processing:</strong> Automate document classification, data extraction, and information retrieval from various formats. Chloe can analyze contracts, invoices, or customer support tickets, saving you time and resources.</li>
                        <li><strong>Personalized Customer Support:</strong> Enhance your customer service with AI-powered chatbots. Train Chloe to answer frequently asked questions, resolve basic issues, and escalate complex matters to human agents.</li>
                        <li><strong>Predictive Maintenance:</strong> Analyze sensor data to predict equipment failures and schedule proactive maintenance. Chloe can help you avoid costly downtime and optimize the performance of your critical machinery.</li>
                    </ul>
                    <p><strong>The possibilities are endless. Chloe empowers you to customize AI solutions that perfectly align with your unique needs and business goals.</strong></p>
                    <h3>Chloe Pricing:</h3>
                    <ul>
                        <li><strong>Playbook Execution:</strong> €3.70 per hour, billed per minute. This ensures you only pay for the compute resources used by your AI workflows.</li>
                        <li><strong>On-Premises Plugins:</strong> €175 per month. On-premises plugins extend Chloe's functionalities for specific use cases within your environment.</li>
                        <li><strong>Workers:</strong> Pricing may vary depending on the worker's specific function. Workers are micro-services designed to perform a single task exceptionally well. They can have one input and multiple outputs, allowing for branched workflows based on the worker's completion.</li>
                        <li><strong>External Providers:</strong> Additional costs may be incurred for integrating with external AI services or tools offered by third-party vendors.</li>
                    </ul>
                    <p><strong>Ready to unlock the power of secure, customizable AI?</strong></p>
                    <p>
                        <a href="/chloe" className="btn btn-default">Try Chloe Now: link to Chloe trial or demo</a>
                    </p>
                    <p>InfinityOps, your trusted partner in secure and responsible AI.</p>

                </div>
            </div>
        </div>
    </>
}

export default ServiceChloePlaybookCustomization;