import React from 'react'
import { Link } from "react-router-dom";
import { FiArrowRight } from "react-icons/fi"
import Separator from "../elements/separator/Separator";


const TimelineData = [
    {
        id: "1",
        date: "First",
        title: "AIOps Consulting",
        description: "Enhance IT operations with our AIOps service. AI-driven solutions offer real-time issue fixing, boosting efficiency and competitiveness.",
        image: "infinityops-consultancy.webp",
        workingStep: [
            {
                stepTitle: "Predictive Analytics",
                stepDescription: "Aanticipate IT failures ensuring uninterrupted operations.",
            },
            {
                stepTitle: "Real-Time Diagnostics",
                stepDescription: "Rapid ident & resolve of IT issues to mitigate downtime",
            },
        ],
        cta: "Transform your operations",
        link: "/services/aiops-consultancy"
    },
    {
        id: "2",
        date: "Then",
        title: "Chloe Playbooks Customization",
        description: "Chloe's AI playbooks tailor business process automation for operational excellence, boosting performance and security.",
        image: "infinityops-chloe-v0.1.0-help.png",
        workingStep: [
            {
                stepTitle: "Workflow Integration",
                stepDescription: "Merge Chloe with your current systems for improved efficiency.",
            },
            {
                stepTitle: "Data Protection",
                stepDescription: "In-house LLM ensure your sensitive information remains secure and compliant.",
            },
        ],
        cta: "Try Chloe now",
        link: "/services/chloe-playbook-customization"
    },
    {
        id: "3",
        date: "Fianally",
        title: "Intelligent Process Automation (IPA)",
        description: "Our IPA service blends AI and RPA, automating routine tasks for unmatched efficiency and growth.",
        image: "infinityops-ipa.webp",
        workingStep: [
            {
                stepTitle: "Cognitive Automation",
                stepDescription: "Apply AI to interpret complex data, enhancing accuracy and insights for better decision-making.",
            },
            {
                stepTitle: "Enhanced Productivity",
                stepDescription: "Free up valuable team time by automating repetitive tasks, focusing on strategic initiatives.",
            },
        ],
        cta: "Add IPA to your operations",
        link: "/services/intelligent-process-automation"
    },
]


const Service = ({
    image,
    title,
    description,
    link
}) => {
    return <>
        <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6 col-12">
            <div className={`card-box card-style-1 text-center`}>
                <div className="inner">
                    <div className="image">
                        <Link to="#service">
                            <img src={`${image}`} alt="card Images" />
                        </Link>
                    </div>
                    <div className="content">
                        <h4 className="title mb--20"><Link to="#service">
                            {title}
                        </Link></h4>
                        <p className="description b1 color-gray mb--0">
                            {description}
                        </p>
                        <Link className="btn-default btn-small btn-border" to={link}>Read More</Link>
                    </div>
                </div>
            </div>
        </div>
    </>
}

const Home = () => {
    return <>
        <div className="slider-area slider-style-1 variation-default height-850 bg_image" data-black-overlay="7" style={{ backgroundImage: `url(/assets/img/infinityops-hero-background-datacenter.png)` }}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="inner text-center">
                            <span className="title display-one">
                                <h1>
                                    Tranforming
                                    <span style={{ color: 'var(--color-primary)' }}>&nbsp;Operations</span>
                                    <br />
                                    with
                                    <span style={{ color: 'var(--color-primary)' }}>&nbsp;AI</span>
                                    &nbsp;&nbsp;
                                </h1>
                            </span>
                            <p className="description">Revolutionize your processes, allowing AI to handle complexity while your team excels in creativity and human.</p>
                            <div className="button-group">
                                <Link className="btn-default btn-transparent btn-border btn-medium btn-icon" to="/chloe">Try Chloe <i className="icon"><FiArrowRight /></i></Link>
                                <Link className="btn-secondary btn-medium btn-icon btn-border" to="https://calendly.com/guilherme-silveira-slz4/30min">Schedule a Demo</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="rwt-about-area rn-section-gap" id="about">
            <div className="container">
                <div className="row row--30 align-items-center">
                    <div className="col-lg-5">
                        <div className="thumbnail">
                            <img className="w-100" src="/assets/img/chloe2.png" alt="About Images" />
                        </div>
                    </div>

                    <div className="col-lg-7 mt_md--40 mt_sm--40">
                        <div className="content">
                            <div className="section-title">
                                <h2 className="title">About us<br /> {" "}
                                </h2>
                                <p>At InfinityOps, we create Artificial Intelligence solutions that optimize processes and free humans for what they are truly good at: creativity and personal relationships.</p>
                                <p>Chloe, our AI agent framework, is specially designed to ensure the confidentiality of your data, allowing the implementation of private information flows that guarantee you full compliance with information security standards.</p>
                                <p>We have consultancy services focused on identifying processes with potential for efficiency improvement that allows us to create Chloe playbooks designed for your needs.</p>
                                <div className="read-more-btn mt--40">
                                    <a className="btn-default" href="https://calendly.com/guilherme-silveira-slz4/30min"><span>Shedule your first free call</span></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <Separator />

        <div className="rn-callto-action clltoaction-style-default style-2 bg_image bg_image_fixed" style={{ backgroundImage: "url('/assets/img/infinityops-cta-background.png')" }} data-black-overlay="8">
            <div className="container">
                <div className="row row--30 align-items-center">
                    <div className="col-lg-10 offset-lg-1">
                        <div className="inner">
                            <div className="content text-center">
                                <div style={{maxWidth: '1000px', margin: 'auto'}}>
                                    <h2 className="title mb-3">Discover Chloe</h2>
                                    <p className='text-center'>Chloe is more than an AI agent; it's a commitment to security and customization.</p>
                                    <p>
                                        Chloe's AI framework is secure-by-design and crafted for businesses striving for excellence while adhering to strict European privacy laws.
                                        With Chloe, you get the freedom to run AI on your premises or on a private cloud environment, orchestrate complex workflows with playbooks, and seamlessly integrate with an unlimited array of tools.
                                    </p>
                                    <div className="call-to-btn text-center">
                                        <a className="btn-default btn-icon" href="/chloe">Meet Chloe <i className="icon"><FiArrowRight /></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <Separator />

        <div className="rwt-about-area rn-section-gap">
            <div className="container">
                <h2 className="text-center">Our services</h2>


                <div className="rn-timeline-wrapper timeline-style-one position-relative">
                    <div className="timeline-line"></div>
                    {/* Start Single Timeline  */}

                    {TimelineData.map((data, index) => (
                        <div className="single-timeline mt--50" key={index}>
                            <div className="timeline-dot">
                                <div className="time-line-circle"></div>
                            </div>
                            <div className="single-content">
                                <div className="inner">
                                    <div className="row row--30 align-items-center">
                                        <div className="order-2 order-lg-1 col-lg-6 mt_md--40 mt_sm--40">
                                            <div className="content">
                                                <span className="date-of-timeline">{data.date}</span>
                                                <h2 className="title">{data.title}</h2>
                                                <p className="description">{data.description}</p>
                                                <div className="row row--30 my-5">
                                                    {data.workingStep.map((data, index) => (
                                                        <div className="col-lg-6 col-md-6 col-12" key={index}>
                                                            <div className="working-list">
                                                                <h5 className="working-title">{data.stepTitle}</h5>
                                                                <p>{data.stepDescription}</p>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                                <div className="read-morebtn mt-5">
                                                    <a className="btn-default btn-large round" href={data.link}><span>{data.cta}</span></a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="order-1 order-lg-2 col-lg-6">
                                            <div className="thumbnail">
                                                <img className="w-100" src={`./assets/img/${data.image}`} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}

                    {/* End Single Timeline  */}
                </div>
            </div>
        </div>



    </>

}

export default Home;