import BlogProp from './itemProp/BlogProp';


const BlogGridSidebar = () => {

    return (
        <div className="rn-blog-area rn-section-gap">
            <div className="container">
                <div className="row row--30">
                    <div className="col-lg-12">
                        <div className="row mt_dec--30">
                            <BlogProp column="col-lg-3 col-md-6 col-sm-6 col-12 mt--30" />
                        </div>
                    </div>
{/* 
                    <div className="col-lg-4 mt_md--40 mt_sm--40">
                        <aside className="rwt-sidebar">

                            <div className="rbt-single-widget widget_search mt--40">
                                <div className="inner">
                                    <form className="blog-search" action="#">
                                        <input type="text" placeholder="Search ..." />
                                        <button className="search-button"><FaSistrix /></button>
                                    </form>
                                </div>
                            </div>


                            <div className="rbt-single-widget widget_categories mt--40">
                                <h3 className="title">Categories</h3>
                                <div className="inner">
                                    <SideCategories />
                                </div>
                            </div>
                            <div className="rbt-single-widget widget_recent_entries mt--40">
                                <h3 className="title">Post</h3>
                                <div className="inner">
                                    <SidebarPost />
                                </div>
                            </div>



                            <div className="rbt-single-widget widget_archive mt--40">
                                <h3 className="title">Archives</h3>
                                <div className="inner">
                                    <SidebarArchive />
                                </div>
                            </div>

                            <div className="rbt-single-widget widget_tag_cloud mt--40">
                                <h3 className="title">Tags</h3>
                                <div className="inner mt--20">
                                    <SidebarTag />
                                </div>
                            </div>


                        </aside>
                    </div> */}
                </div>
            </div>
        </div>
    )
}

export default BlogGridSidebar
