import { getFunctions, httpsCallable } from "firebase/functions";
import { initializeApp } from "firebase/app";
import { redirect } from "react-router-dom";

const firebaseConfig = {
  apiKey: "AIzaSyDEqjL0Q9yu_3dPq1t6_tKoNPIyjGvpPcc",
  authDomain: "infinityops-386ed.firebaseapp.com",
  projectId: "infinityops-386ed",
  storageBucket: "infinityops-386ed.appspot.com",
  messagingSenderId: "435038721288",
  appId: "1:435038721288:web:67d2d10335a91aa319c8bd",
  measurementId: "G-CFX63RNT81"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
let functions = getFunctions(app);
let functionsUrl = (url) => url

if(process.env.NODE_ENV === 'development') {
    functions = getFunctions(app, 'http://localhost:5001');
    functionsUrl = (url) => `infinityops-386ed/us-central1/${url}`
}

let GET  = (url) => {
    httpsCallable(functions, functionsUrl(url))()
    .then((res) => {
        return res.data
    }).catch((err) => {
        return err
    }
)}

let POST = (url, data) => {
    httpsCallable(functions, functionsUrl(url))(data || {})
    .then((res) => {
        return res.data
    }).catch((err) => {
        return err
    }
)}


export const SendChloeSignupChat = async (data) => POST('ChloeSignup', data);
export const SendContactForm = async (data) => POST('ContactForm', data);
export const SubscribeToNewsletter = async (data) => POST('SubscribeToNewsletter', data);
export const GetArticles = async () => {
    const res = await fetch('/data/articles.json')
    return res.json();
}

export const GetArticle = async (id) => {
    const res = await fetch('/data/articles.json')
    const articles = await res.json();

    const article = articles.filter((article) => String(article.id) === String(id))[0];

    return article;
}

// export const GetArticlesByTag = async (tag) => {
//     return require('/data/articles.json').filter((article) => article.tags.includes(tag))
// }
// export const GetArticlesByCategory = async (category) => {
//     return require('/data/articles.json').filter((article) => article.categories.includes(category))
// }
// export const GetArticlesByAuthor = async (author) => {
//     return require('/data/articles.json').filter((article) => article.author.includes(author))
// }
// export const GetArticlesByKeyword = async (keyword) =>  {
//     return require('/data/articles.json').filter((article) => article.title.includes(keyword) || article.content.includes(keyword))
// }
export default {
    SendChloeSignupChat,
    SendContactForm,
    SubscribeToNewsletter,

    //Blog
    GetArticles,
    GetArticle,
    // GetArticlesByTag,
    // GetArticlesByCategory,
    // GetArticlesByAuthor,
    // GetArticlesByKeyword
}
