// import SidebarTag from './sidebar/SidebarTag';
// import SideCategories from './sidebar/SideCategories';
// import Comment from './Comment';
// import {slugify} from "../../utils";
// import {Link} from "react-router-dom";
import { useEffect, useState } from "react";
import { FiUser, FiCalendar } from "react-icons/fi";
import { useParams } from "react-router-dom";
import api from "../../api/api";


const BlogDetailsContent = () => {
    const [data, setData] = useState({});
    const {id} = useParams();

    useEffect(() => {
        const getArticle = async () => {
            const d = await api.GetArticle(id)
            if (!d) {
                setData({});
                return;
            }
            setData(d);
        }

        getArticle();
    }, []);

    if (data == {} || data.id != id || data.title == "") {
        return <>
        
        <div className="post-page-banner rn-section-gapTop">
                <div className="container">
                    <div className="row">
                        loading ...
                    </div>
                </div>
            </div>
        </>
    }

    return (
        <>
            <div className="post-page-banner rn-section-gapTop">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 offset-lg-2">
                            <div className="content text-center">
                                <div className="page-title">
                                    <h1 className="theme-gradient display-3">{data.title}</h1>
                                </div>
                                <ul className="rn-meta-list">
                                    <li><FiUser />{data.author}</li>
                                    {/* <li><FiUser /><Link to={`/archive/${slugify(data.author)}`}>{data.author}</Link></li> */}
                                    <li><FiCalendar />{data.date}
                                    </li>
                                </ul>
                                <div className="thumbnail alignwide mt--60">
                                    <img className="w-50 radius" src={`/${data.image}`} alt="Blog Images" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="blog-details-content pt--60 rn-section-gapBottom">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 offset-lg-2">
                            <div className="content" dangerouslySetInnerHTML={{__html: data.body}}>

                            
                                {/* 
                                <div className="category-meta">
                                    <span className="text">Tags:</span>
                                    <SidebarTag />
                                </div> 
                                */}

                                {/* 
                                
                                <div className="rn-comment-form pt--60">
                                    <div className="comment-respond">
                                        <h4 className="title mb--40">Leave a Reply</h4>
                                        <Comment 
                                            url=""
                                            id={data.id}
                                            title={data.title}
                                        />
                                    </div>
                                </div>

                                */}
                            </div> 
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default BlogDetailsContent;
