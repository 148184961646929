
import { Outlet } from "react-router-dom"
import SEO from "../common/SEO";
import HeaderOne from '../common/header/HeaderOne';
import HeaderTopNews from '../common/header/HeaderTopNews';
import FooterOne from '../common/footer/FooterOne';
import Copyright from '../common/footer/Copyright';

const MainLayout = () => {

    return <>
        <SEO />
        <main className="page-wrapper">
            {/* <HeaderTopNews /> */}
            <HeaderOne btnStyle="btn-small round btn-icon" HeaderSTyle="header-transparent" />
            <Outlet />
            <FooterOne />
            <Copyright />
        </main>
    </>
}

export default MainLayout