import { BrowserRouter, Routes, Route } from 'react-router-dom';
import MainLayout from './layouts/MainLayout';


// Pages import Here 
import Home from "./pages/Home"
import Error from "./pages/Error";
import ChloeSignup from './pages/ChloeSignup';
import BlogGridSidebar from './components/blog/BlogGridSidebar';
import BlogDetails from './components/blog/BlogDetailsContent';
import Contact from './elements/contact/Contact';
import AboutUs from './pages/AboutUs';
import ServiceAIOpsConsultancy  from './pages/ServiceAIOpsConsultancy';
import ServiceChloePlaybookCustomization from './pages/ServiceChloePlaybookCustomization';
import ServiceIPA from './pages/ServiceIPA';



// Import Css Here 
import './assets/scss/style.scss';



const App = () => {
    return <>
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<MainLayout />}>
                    <Route path="" element={<Home />} />
                    <Route path="chloe" element={<ChloeSignup />} />
                    <Route path="blog" element={<BlogGridSidebar />} />
                    <Route path="blog-details/:id" element={<BlogDetails />} />
                    <Route path="contact" element={<Contact />} />
                    <Route path="about" element={<AboutUs />} />
                    <Route path="services/aiops-consultancy" element={<ServiceAIOpsConsultancy />} />
                    <Route path="services/chloe-playbook-customization" element={<ServiceChloePlaybookCustomization />} />
                    <Route path="services/intelligent-process-automation" element={<ServiceIPA />} />
                </Route>
                <Route path={'*'} element={<Error />} />
            </Routes>
        </BrowserRouter>
    </>

}


export default App
